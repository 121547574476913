import React, { useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { getId, getLic } from '../util/ID'
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider'
import InputAdornment from '@mui/material/InputAdornment';

export default function LoveSetting1(props) {
    const [open, setOpen] = React.useState(false);
    const [count, setCount] = React.useState(localStorage.getItem("GRID_COUNT") != null ? localStorage.getItem("GRID_COUNT") : "10")
    const [money, setMoney] = React.useState(localStorage.getItem("GRID_MONEY") != null ? localStorage.getItem("GRID_MONEY") : "100000")
    const [money1, setMoney1] = React.useState(localStorage.getItem("GRID_MONEY1") != null ? localStorage.getItem("GRID_MONEY1") : "50000")
    const [upPercent, setUpPercent] = React.useState(localStorage.getItem("GRID_UP_PERCENT") != null ? localStorage.getItem("GRID_UP_PERCENT") : "100")
    const [downPercent, setDownPercent] = React.useState(localStorage.getItem("GRID_DOWN_PERCENT") != null ? localStorage.getItem("GRID_DOWN_PERCENT") : "30")
    const [value, setValue] = React.useState(dayjs());

    const [sliptTp, setSplitTp] = React.useState(localStorage.getItem("GRID_SLIPT_TP") != null ? localStorage.getItem("GRID_SLIPT_TP") : "0")
    const [percent, setPercent] = React.useState(localStorage.getItem("GRID_PERCENT") != null ? localStorage.getItem("GRID_PERCENT") : "10")
    const [bsTp, setBsTp] = React.useState(localStorage.getItem("GRID_BS_TP") != null ? localStorage.getItem("GRID_BS_TP") : "0")
    const [bsCount, setBsCount] = React.useState(localStorage.getItem("GRID_BS_COUNT") != null ? localStorage.getItem("GRID_BS_COUNT") : "1000")
    const [bsPercent, setBsPercent] = React.useState(localStorage.getItem("GRID_BS_PERCENT") != null ? localStorage.getItem("GRID_BS_PERCENT") : "10")

    const handleClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
        // if(props.refresh != null) props.refresh()
    };


    const handleOKClose = () => {
        let param =
        {
            sid: props.sid,
            sn: getId(),
            gbr: {
                test_month: 1,
                start_date: dayjs(value, "YYYY-MM-DD").format('YYYYMMDD'),
                money: money,
                money1: money1,
                inv_cash_percent: (money1 * 100) / money,
                net: {
                    split_tp: sliptTp,
                    split_count: count,
                    split_percent: percent,
                    up_down_tp: 0,
                    up_percent: upPercent,
                    down_percent: downPercent,
                    bs_tp: bsTp,
                    bs_count: bsCount,
                    bs_percent: bsPercent    

                }

            }
        }

        if (localStorage.getItem("grid_lic") != null) {
            param['lic'] = localStorage.getItem("grid_lic")
        }


        fetch("/grid/v1/love/click", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null && result.status === 0) {
                        setOpen(false);
                        if (props.close != null) props.close(false)

                        if (props.refresh != null) {
                            props.refresh()
                        }
                    } else {
                    }
                }
            );
    }


    useEffect(() => {
        if (props.open) setOpen(true)
        else setOpen(false)
    }, [props.open]);



    return (
        <div>
            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle id="alert-dialog-title">
                    {"新增我的計畫"}

                </DialogTitle>
                <DialogContent sx={{ pl: 1, pr: 1 }}>
                    <Grid container spacing={2} sx={{ flexGrow: 1, mt: 0.5 }}>

                        <Grid xs={12} md={6} >
                            <FormControl fullWidth >
                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                    <DatePicker
                                        format="YYYY-MM-DD"
                                        label="投資起始日"
                                        value={value}
                                        onChange={(newValue) => setValue(newValue)}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>

                        <Grid xs={12} md={6} >
                            <FormControl fullWidth >
                                <InputLabel id="demo-simple-select-label">總資金</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="總資金"
                                    value={money}
                                    onChange={event => setMoney(event.target.value)}
                                >
                                    <MenuItem value="10000">1萬</MenuItem>
                                    <MenuItem value="20000">2萬</MenuItem>
                                    <MenuItem value="30000">3萬</MenuItem>
                                    <MenuItem value="40000">4萬</MenuItem>
                                    <MenuItem value="50000">5萬</MenuItem>
                                    <MenuItem value="60000">6萬</MenuItem>
                                    <MenuItem value="70000">7萬</MenuItem>
                                    <MenuItem value="80000">8萬</MenuItem>
                                    <MenuItem value="90000">9萬</MenuItem>
                                    <MenuItem value="100000">10萬</MenuItem>
                                    <MenuItem value="150000">15萬</MenuItem>
                                    <MenuItem value="200000">20萬</MenuItem>
                                    <MenuItem value="250000">25萬</MenuItem>
                                    <MenuItem value="300000">30萬</MenuItem>
                                    <MenuItem value="350000">35萬</MenuItem>
                                    <MenuItem value="400000">40萬</MenuItem>
                                    <MenuItem value="450000">45萬</MenuItem>
                                    <MenuItem value="500000">50萬</MenuItem>
                                    <MenuItem value="550000">55萬</MenuItem>
                                    <MenuItem value="600000">60萬</MenuItem>
                                    <MenuItem value="650000">65萬</MenuItem>
                                    <MenuItem value="700000">70萬</MenuItem>
                                    <MenuItem value="750000">75萬</MenuItem>
                                    <MenuItem value="800000">80萬</MenuItem>
                                    <MenuItem value="850000">85萬</MenuItem>
                                    <MenuItem value="900000">90萬</MenuItem>
                                    <MenuItem value="950000">95萬</MenuItem>
                                    <MenuItem value="1000000">100萬</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid xs={12} md={6} >
                            <FormControl fullWidth >
                                <InputLabel id="demo-simple-select-label1">起始投資資金</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label1"
                                    id="demo-simple-select1"
                                    label="起始投資資金"
                                    value={money1}
                                    onChange={event => setMoney1(event.target.value)}
                                >
                                    <MenuItem value="10000">1萬</MenuItem>
                                    <MenuItem value="20000">2萬</MenuItem>
                                    <MenuItem value="30000">3萬</MenuItem>
                                    <MenuItem value="40000">4萬</MenuItem>
                                    <MenuItem value="50000">5萬</MenuItem>
                                    <MenuItem value="60000">6萬</MenuItem>
                                    <MenuItem value="70000">7萬</MenuItem>
                                    <MenuItem value="80000">8萬</MenuItem>
                                    <MenuItem value="90000">9萬</MenuItem>
                                    <MenuItem value="100000">10萬</MenuItem>
                                    <MenuItem value="150000">15萬</MenuItem>
                                    <MenuItem value="200000">20萬</MenuItem>
                                    <MenuItem value="250000">25萬</MenuItem>
                                    <MenuItem value="300000">30萬</MenuItem>
                                    <MenuItem value="350000">35萬</MenuItem>
                                    <MenuItem value="400000">40萬</MenuItem>
                                    <MenuItem value="450000">45萬</MenuItem>
                                    <MenuItem value="500000">50萬</MenuItem>
                                    <MenuItem value="550000">55萬</MenuItem>
                                    <MenuItem value="600000">60萬</MenuItem>
                                    <MenuItem value="650000">65萬</MenuItem>
                                    <MenuItem value="700000">70萬</MenuItem>
                                    <MenuItem value="750000">75萬</MenuItem>
                                    <MenuItem value="800000">80萬</MenuItem>
                                    <MenuItem value="850000">85萬</MenuItem>
                                    <MenuItem value="900000">90萬</MenuItem>
                                    <MenuItem value="950000">95萬</MenuItem>
                                    <MenuItem value="1000000">100萬</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid xs={12} md={6} >
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label4">天花板價</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label4"
                                    id="demo-simple-select4"
                                    label="天花板價"
                                    value={upPercent}
                                    onChange={event => setUpPercent(event.target.value)}
                                >
                                    <MenuItem value="1">近1年最高價</MenuItem>
                                    <MenuItem value="2">近2年最高價</MenuItem>
                                    <MenuItem value="3">近3年最高價</MenuItem>
                                    <MenuItem value="4">近4年最高價</MenuItem>
                                    <MenuItem value="10">首次買入價 * 110%</MenuItem>
                                    <MenuItem value="20">首次買入價 * 120%</MenuItem>
                                    <MenuItem value="30">首次買入價 * 130%</MenuItem>
                                    <MenuItem value="40">首次買入價 * 140%</MenuItem>
                                    <MenuItem value="50">首次買入價 * 150%</MenuItem>
                                    <MenuItem value="60">首次買入價 * 160%</MenuItem>
                                    <MenuItem value="70">首次買入價 * 170%</MenuItem>
                                    <MenuItem value="80">首次買入價 * 180%</MenuItem>
                                    <MenuItem value="90">首次買入價 * 190%</MenuItem>
                                    <MenuItem value="100">首次買入價 * 200%</MenuItem>
                                    <MenuItem value="150">首次買入價 * 250%</MenuItem>
                                    <MenuItem value="200">首次買入價 * 300%</MenuItem>
                                    <MenuItem value="250">首次買入價 * 350%</MenuItem>
                                    <MenuItem value="300">首次買入價 * 400%</MenuItem>
                                    <MenuItem value="350">首次買入價 * 450%</MenuItem>
                                    <MenuItem value="400">首次買入價 * 500%</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid xs={12} md={6} >
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label6">地板價</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label6"
                                    id="demo-simple-select6"
                                    label="地板價"
                                    value={downPercent}
                                    onChange={event => setDownPercent(event.target.value)}
                                >
                                    <MenuItem value="1">近1年最低價</MenuItem>
                                    <MenuItem value="2">近2年最低價</MenuItem>
                                    <MenuItem value="3">近3年最低價</MenuItem>
                                    <MenuItem value="4">近4年最低價</MenuItem>
                                    <MenuItem value="5">首次買入價 * 95%</MenuItem>
                                    <MenuItem value="10">首次買入價 * 90%</MenuItem>
                                    <MenuItem value="20">首次買入價 * 80%</MenuItem>
                                    <MenuItem value="30">首次買入價 * 70%</MenuItem>
                                    <MenuItem value="40">首次買入價 * 60%</MenuItem>
                                    <MenuItem value="50">首次買入價 * 50%</MenuItem>
                                    <MenuItem value="60">首次買入價 * 40%</MenuItem>
                                    <MenuItem value="70">首次買入價 * 30%</MenuItem>
                                    <MenuItem value="80">首次買入價 * 20%</MenuItem>
                                    <MenuItem value="90">首次買入價 * 10%</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Divider sx={{ mt: 4 }}>
                        <Typography>分割方式</Typography>
                    </Divider>
                    <Grid container spacing={2} sx={{ flexGrow: 1, mt: 0.5 }}>
                        <Grid xs={12} md={6} >
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label3">固定網格</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label3"
                                    id="demo-simple-select3"
                                    label="固定網格"
                                    value={count}
                                    onChange={event => setCount(event.target.value)}
                                >
                                    <MenuItem value="5">5條</MenuItem>
                                    <MenuItem value="6">6條</MenuItem>
                                    <MenuItem value="7">7條</MenuItem>
                                    <MenuItem value="8">8條</MenuItem>
                                    <MenuItem value="9">9條</MenuItem>
                                    <MenuItem value="10">10條</MenuItem>
                                    <MenuItem value="11">11條</MenuItem>
                                    <MenuItem value="12">12條</MenuItem>
                                    <MenuItem value="13">13條</MenuItem>
                                    <MenuItem value="14">14條</MenuItem>
                                    <MenuItem value="15">15條</MenuItem>
                                    <MenuItem value="16">16條</MenuItem>
                                    <MenuItem value="17">17條</MenuItem>
                                    <MenuItem value="18">18條</MenuItem>
                                    <MenuItem value="19">19條</MenuItem>
                                    <MenuItem value="20">20條</MenuItem>
                                    <MenuItem value="25">25條</MenuItem>
                                    <MenuItem value="30">30條</MenuItem>
                                    <MenuItem value="35">35條</MenuItem>
                                    <MenuItem value="40">40條</MenuItem>
                                    <MenuItem value="45">45條</MenuItem>
                                    <MenuItem value="50">50條</MenuItem>
                                    <MenuItem value="60">60條</MenuItem>
                                    <MenuItem value="70">70條</MenuItem>
                                    <MenuItem value="80">80條</MenuItem>
                                    <MenuItem value="90">90條</MenuItem>
                                    <MenuItem value="100">100條</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid xs={12} md={6} >
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label3">固定%</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label3"
                                    id="demo-simple-select3"
                                    label="固定%"
                                    value={percent}
                                    onChange={event => setPercent(event.target.value)}
                                >
                                    <MenuItem value="1">1%</MenuItem>
                                    <MenuItem value="2">2%</MenuItem>
                                    <MenuItem value="3">3%</MenuItem>
                                    <MenuItem value="4">4%</MenuItem>
                                    <MenuItem value="5">5%</MenuItem>
                                    <MenuItem value="6">6%</MenuItem>
                                    <MenuItem value="7">7%</MenuItem>
                                    <MenuItem value="8">8%</MenuItem>
                                    <MenuItem value="9">9%</MenuItem>
                                    <MenuItem value="10">10%</MenuItem>
                                    <MenuItem value="11">11%</MenuItem>
                                    <MenuItem value="12">12%</MenuItem>
                                    <MenuItem value="13">13%</MenuItem>
                                    <MenuItem value="14">14%</MenuItem>
                                    <MenuItem value="15">15%</MenuItem>
                                    <MenuItem value="16">16%</MenuItem>
                                    <MenuItem value="17">17%</MenuItem>
                                    <MenuItem value="18">18%</MenuItem>
                                    <MenuItem value="19">19%</MenuItem>
                                    <MenuItem value="20">20%</MenuItem>
                                    <MenuItem value="25">25%</MenuItem>
                                    <MenuItem value="30">30%</MenuItem>
                                    <MenuItem value="35">35%</MenuItem>
                                    <MenuItem value="40">40%</MenuItem>
                                    <MenuItem value="45">45%</MenuItem>
                                    <MenuItem value="50">50%</MenuItem>
                                    <MenuItem value="60">60%</MenuItem>
                                    <MenuItem value="70">70%</MenuItem>
                                    <MenuItem value="80">80%</MenuItem>
                                    <MenuItem value="90">90%</MenuItem>
                                    <MenuItem value="100">100%</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid xs={12} md={6} >
                            <FormControl>
                                {/* <FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel> */}
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    value={sliptTp}
                                    onChange={(event) => {
                                        setSplitTp(event.target.value);
                                    }}
                                    name="radio-buttons-group"
                                >
                                    <FormControlLabel value="0" control={<Radio />} label={"地板價往上固定網格"} />
                                    <FormControlLabel value="1" control={<Radio />} label={"地板價往上固定% "} />
                                    <FormControlLabel value="2" control={<Radio />} label={"首次買入價上下固定% "} />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>


                    <Divider sx={{ mt: 3 }}>
                        <Typography>買/賣股數</Typography>
                    </Divider>
                    <Grid container spacing={2} sx={{ flexGrow: 1, mt: 0.5 }}>
                        <Grid xs={12} md={6} >
                            <TextField InputProps={{
                                endAdornment: <InputAdornment position="start">股</InputAdornment>,
                            }} fullWidth id="outlined-basic" label="固定股數" variant="outlined" value={bsCount}
                                onChange={(event) => {
                                    setBsCount(event.target.value);
                                }} />
                        </Grid>
                        <Grid xs={12} md={6} >
                            <TextField InputProps={{
                                endAdornment: <InputAdornment position="start">%</InputAdornment>,
                            }} fullWidth id="outlined-basic1" label="固定庫存" variant="outlined" value={bsPercent}
                                onChange={(event) => {
                                    setBsPercent(event.target.value);
                                }} />
                        </Grid>

                        <Grid xs={12} md={6} >
                            <FormControl>
                                {/* <FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel> */}
                                <RadioGroup
                                    value={bsTp}
                                    onChange={(event) => {
                                        setBsTp(event.target.value);
                                    }}
                                    name="radio-buttons-group1"
                                >
                                    <FormControlLabel value="0" control={<Radio />} label={"投資跟保留現金固定比率"} />
                                    <FormControlLabel value="1" control={<Radio />} label={"固定股數"} />
                                    <FormControlLabel value="2" control={<Radio />} label={"庫存%"} />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions>
                    {/* <Button onClick={clearRules}>清除規則</Button> */}
                    <Button onClick={handleClose}>取消</Button>
                    {/* isabled={localStorage.getItem("noad") != "1"} */}
                    {/* {localStorage.getItem("noad") === "1" ? <Button onClick={handleOKClose} autoFocus>
                        確定
                    </Button> : <Button disabled autoFocus>
                        確定 (VIP專屬)
                    </Button>
                    } */}

                    <Button onClick={handleOKClose} autoFocus>
                        確定
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
