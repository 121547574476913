import React, { useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useTheme } from '@mui/material/styles';
import { Height } from '../util/ScreenHeight'
// import ErrorM from '../util/ErrorM'
// import AskDialog from '../util/AskDialog';
// import Photo from '@mui/icons-material/Photo'
// import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import MoreVert from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import RecSetting from './RecSetting';

import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import OutlinedInput from '@mui/material/OutlinedInput';
import AlertD from '../util/AlertD';
import { Stack, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import SearchCompany from '../love/SearchCompany';

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -10,
        top: 5,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 10 + ITEM_PADDING_TOP,
            // width: 250,
        },
    },
};


const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiOutlinedInput-root': {
            borderRadius: 0
        },
    }

    //  '& .MuiDataGrid-colCell, .MuiDataGrid-cell': {
    //     borderRight: `1px solid ${theme.palette.type === 'light' ? '#f0f0f0' : '#303030'
    //         }`,
    // },
}));


export default function RecSelector(props) {
    const [showAlert, setShowAlert] = React.useState(false);
    const [alertMsg, setAlertMsg] = React.useState(null);

    const [openSetting, setOpenSetting] = React.useState(false);

    const theme = useTheme();
    const classes = useStyles();

    const [month, setMonth] = React.useState(localStorage.getItem("GRID_MONTH") != null ? localStorage.getItem("GRID_MONTH") : "1");
    const [sort, setSort] = React.useState(localStorage.getItem("GRID_SORT") != null ? localStorage.getItem("GRID_SORT") : "0");
    const [sid, setSID] = React.useState(null);

    const handleYearChange = (event) => {
        if (localStorage.getItem("noad") === "1" || event.target.value <= 3) {
            setMonth(event.target.value)
            localStorage.setItem("GRID_MONTH", event.target.value)
        } 
    };

    const handleSortChange = (event) => {
        setSort(event.target.value)
        localStorage.setItem("GRID_SORT", event.target.value)
    };


    useEffect(() => {
        if (props.refresh != null) props.refresh(sort, month, sid)
    }, [sort, month, sid]);

    const hasLic = () => {
        return localStorage.getItem('noad') != null && localStorage.getItem('noad') === "1"
    }


    return (
        <Box sx={{ width: '100%', textAlign: 'left', display: 'inline-flex', p: 0, bgcolor: theme.palette.mode === "light" ? "secondary.light" : null }} >
            {openSetting ? <RecSetting refresh={props.fetchData} open={openSetting} close={setOpenSetting}></RecSetting> : null}
            {showAlert ? <AlertD desc={alertMsg} open={showAlert} close={setShowAlert}></AlertD> : null}

            <Stack direction={"row"} spacing={0.2} sx={{ width: "100%", height: Height.subheader - 3, maxHeight: Height.subheader }}>
                <Box sx={{ width: "100%" }}>
                    <SearchCompany setSID={setSID} />
                </Box>
                <FormControl variant="filled" fullWidth hiddenLabel >
                    <Select
                        labelId="yearLabel"
                        id="year"
                        value={month}
                        label="yearLabel"
                        onChange={handleYearChange}
                    >
                        <MenuItem value={1}>1個月</MenuItem>
                        <MenuItem value={2}>2個月</MenuItem>
                        <MenuItem value={3}>3個月</MenuItem>
                        {<MenuItem value={4}>{localStorage.getItem("noad") === "1" ? "4個月" : "4個月（VIP）"}</MenuItem>}
                        {<MenuItem value={5}>{localStorage.getItem("noad") === "1" ? "5個月" : "5個月（VIP）"}</MenuItem>}
                        {<MenuItem value={6}>{localStorage.getItem("noad") === "1" ? "6個月" : "6個月（VIP）"}</MenuItem>}
                        {<MenuItem value={7}>{localStorage.getItem("noad") === "1" ? "7個月" : "7個月（VIP）"}</MenuItem>}
                        {<MenuItem value={8}>{localStorage.getItem("noad") === "1" ? "8個月" : "8個月（VIP）"}</MenuItem>}
                        {<MenuItem value={9}>{localStorage.getItem("noad") === "1" ? "9個月" : "9個月（VIP）"}</MenuItem>}
                        {<MenuItem value={10}>{localStorage.getItem("noad") === "1" ? "10個月" : "10個月（VIP）"}</MenuItem>}
                        {<MenuItem value={11}>{localStorage.getItem("noad") === "1" ? "11個月" : "11個月（VIP）"}</MenuItem>}
                        {<MenuItem value={12}>{localStorage.getItem("noad") === "1" ? "12個月" : "12個月（VIP）"}</MenuItem>}
                    </Select>
                </FormControl>
                <FormControl variant="filled" fullWidth hiddenLabel >
                    <Select
                        labelId="sortLabel"
                        id="sort"
                        value={sort}
                        label="sortLabel"
                        onChange={handleSortChange}
                    >
                        <MenuItem value={0}>整體%</MenuItem>
                        <MenuItem value={1}>已實現%</MenuItem>
                        <MenuItem value={2}>未實現%</MenuItem>
                        <MenuItem value={3}>買賣次數</MenuItem>
                        <MenuItem value={4}>賣出次數</MenuItem>
                        <MenuItem value={5}>買入次數</MenuItem>
                    </Select>
                </FormControl>

            </Stack>
            {/* {props.enableSetting && <Button variant='contained' sx={{ height: Height.subheader - 3, maxHeight: Height.subheader, whiteSpace: 'nowrap', borderRadius: '0px', mr: 0.2}} size="small" color="secondary" disableElevation onClick={e => clear()}>清除</Button>} */}
            {<Button variant='contained' sx={{ height: Height.subheader - 3, maxHeight: Height.subheader, whiteSpace: 'nowrap', borderRadius: '0px' }} size="small" color="error" disableElevation onClick={e => setOpenSetting(true)}>更多</Button>}
        </Box>
    );
}
