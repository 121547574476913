import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart, BarChart, CandlestickChart } from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    MarkAreaComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
    VisualMapComponent,
    VisualMapContinuousComponent,
    VisualMapPiecewiseComponent,
} from 'echarts/components';
import {
    CanvasRenderer,
} from 'echarts/renderers';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { blue, orange } from '@mui/material/colors';
import Chip from '@mui/material/Chip';
import { Typography } from '@mui/material';
import { priceWord } from '../util/Money'

echarts.use(
    [VisualMapComponent, VisualMapContinuousComponent, VisualMapPiecewiseComponent, TitleComponent, TooltipComponent, GridComponent, LineChart, CandlestickChart, CanvasRenderer, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent, MarkAreaComponent, BarChart]
);


const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        // marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        // width: `calc(100% + ${6 + (theme.spacing(1))}px)`
    }
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        // backgroundColor: theme.palette.common.black,
        // color: theme.palette.common.white,
        whiteSpace: "nowrap"
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
        whiteSpace: "nowrap"

    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
        whiteSpace: "nowrap"
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
        whiteSpace: "nowrap"
    },
}));

const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
}));

export default function KDChart(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [option, setOption] = React.useState(null);
    const [result, setResult] = React.useState(null);

    const [open, setOpen] = React.useState(false);

    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
    };

    useEffect(() => {
        if (props.open) {
            setOpen(true)
            fetchData()
        }
        else setOpen(false)
    }, [props.open]);


    const fetchData = () => {
        let count = localStorage.getItem("GRID_COUNT") != null ? localStorage.getItem("GRID_COUNT") : "10"
        let money = localStorage.getItem("GRID_MONEY") != null ? localStorage.getItem("GRID_MONEY") : "100000"
        let money1 = localStorage.getItem("GRID_MONEY1") != null ? localStorage.getItem("GRID_MONEY1") : "50000"
        let upPercent = localStorage.getItem("GRID_UP_PERCENT") != null ? localStorage.getItem("GRID_UP_PERCENT") : "100"
        let downPercent = localStorage.getItem("GRID_DOWN_PERCENT") != null ? localStorage.getItem("GRID_DOWN_PERCENT") : "30"
        let month = localStorage.getItem("GRID_MONTH") != null ? localStorage.getItem("GRID_MONTH") : "6"

        let sliptTp = localStorage.getItem("GRID_SLIPT_TP") != null ? localStorage.getItem("GRID_SLIPT_TP") : "0"
        let percent = localStorage.getItem("GRID_PERCENT") != null ? localStorage.getItem("GRID_PERCENT") : "10"
        let bsTp = localStorage.getItem("GRID_BS_TP") != null ? localStorage.getItem("GRID_BS_TP") : "0"
        let bsCount = localStorage.getItem("GRID_BS_COUNT") != null ? localStorage.getItem("GRID_BS_COUNT") : "1000"
        let bsPercent = localStorage.getItem("GRID_BS_PERCENT") != null ? localStorage.getItem("GRID_BS_PERCENT") : "10"

        let param = {
            sid: props.stock,
            gbr: {
                test_month: month,
                money: money,
                money1: money1,
                inv_cash_percent: (money1 * 100) / money,
                net: {
                    split_tp: sliptTp,
                    split_count: count,
                    split_percent: percent,
                    up_down_tp: 0,
                    up_percent: upPercent,
                    down_percent: downPercent,
                    bs_tp: bsTp,
                    bs_count: bsCount,
                    bs_percent: bsPercent
                }
            }

        }

        if (localStorage.getItem("grid_lic") != null) {
            param['lic'] = localStorage.getItem("grid_lic")
        }

        fetch("/grid/v1/detail", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null) {
                        setResult(result)
                    }
                }
            );
    }




    useEffect(() => {
        if (result != null) {
            setOption(genoption2(result, "日K"))
        }
    }, [result]);

    // useEffect(() => {
    //     if (props.stock != null) fetchData()
    // }, []);

    const getPoint = (vv) => {
        let sellPoints = vv.tran.sell.map(v => {
            return {
                coord: [v.symd, v.sprice],
                itemStyle: {
                    color: blue[500]
                },
                symbol: "triangle",
                symbolRotate: -180,
                symbolOffset: [0, -10]
            }
        })

        let buyPoints = vv.tran.buy.map(v => {
            return {
                coord: [v.ymd, v.price],
                itemStyle: {
                    color: orange[500]
                },
                symbol: "triangle",
                symbolRotate: 0,
                symbolOffset: [0, 10]
            }
        })

        return sellPoints.concat(buyPoints)
    }

    const getMarkLine = (vv) => {
        let a = vv.tran.key_price.map(v => {
            return {
                yAxis: v
            }
        })

        let maxMin = [{
            lineStyle: {
                width: 3,
                type: "solid"
            },
            yAxis: vv.tran.max_price
        }, {
            lineStyle: {
                width: 3,
                type: "solid"
            },
            yAxis: vv.tran.min_price
        }]

        return a.concat(maxMin)
    }
    function genoption2(vv, title) {
        let k = {
            animation: false,
            tooltip: {
                show: true
            },
            // legend: {
            //     show: true
            // },
            // title: [
            //     {
            //         top: '30',
            //         left: 0,
            //         left: 'left',
            //         text: props.name + "(" + props.stock + ")",
            //         textStyle: {
            //             fontWeight: "normal",
            //             fontSize: 22
            //         }
            //     }
            // ],
            grid: [{
                top: 30,
                left: 0,
                right: 10,
                height: 350,
                containLabel: true
            }],
            axisPointer: {
                show: true,
                link: { xAxisIndex: 'all' },
            },
            dataZoom: [
                {
                    type: 'inside',
                    start: fullScreen ? 75 : 0,
                    end: 100,
                    xAxisIndex: 'all',
                }
            ],
            xAxis: [{
                type: 'category',
                boundaryGap: true,
                data: vv.prices.map(row => row.d),

            }],
            yAxis: [{
                scale: true,
                type: 'value',
                position: 'left',
                show: true,
                splitLine: {
                    show: true,
                    lineStyle: {
                        opacity: 0.2
                    }
                },
                axisLabel: {
                    inside: true,
                    margin: 0
                },
            }],
            series: [
                {
                    name: '股價',
                    type: 'candlestick',
                    data: vv.prices.map(v => { return [v.o, v.p, v.l, v.h] }),
                    selectedMode: 'single',
                    markLine: {
                        symbol: "none",
                        label: {
                            show: false
                        },
                        data: getMarkLine(vv)

                    },
                    markPoint: {
                        symbol: "circle",
                        symbolSize: 15,
                        data: getPoint(vv)
                    }
                }
            ]
        };



        return k;
    }

    const getAll = () => {
        if (result != null) {
            result.tran.buy.concat(result.tran.sell).map(row => {
                let tp = 0
                let ymd = row.ymd

                if (row.symd != null) {
                    tp = 1
                    ymd = row.symd
                }


            })
        }

    }


    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
        >
            <DialogTitle sx={{ p: 1 }}>
                <Typography variant='h6'>{props.name + "(" + props.stock + ")"}買賣價格圖</Typography>

            </DialogTitle>
            <DialogContent sx={{ pl: 1, pr: 1 }}>
                {/* <Typography sx={{ mt: 2 }} variant='h5'>{props.name}{props.stock}
                    {props.id === 2 ? "主力買超" : "主力賣超"}</Typography> */}

                {/* <Typography variant='h6' sx={{ mt: 2 }}></Typography> */}


                {option != null ?
                    <ReactEChartsCore
                        // theme={theme.palette.mode}
                        echarts={echarts}
                        option={option}
                        notMerge={true}
                        lazyUpdate={false}
                        style={{ height: 400, marginTop: 0 }}
                    /> : null}
                {result != null && <Paper
                    variant="elevation"
                    elevation={1}

                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexWrap: 'wrap',
                        listStyle: 'none',
                        p: 0.5,
                        m: 0,
                    }}
                    component="ul"
                >
                    {result.tran.key_price.map((data) => {
                        let icon;

                        return (
                            <ListItem key={Math.random()}>
                                <Chip
                                    icon={icon}
                                    label={data + "元"}
                                    variant="outlined"
                                />
                            </ListItem>
                        );
                    })}
                </Paper>}

                <TableContainer sx={{ mt: 2 }} component={Paper} fullWidth>
                    <Table stickyHeader size="medium"  >
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>型態</StyledTableCell>
                                <StyledTableCell>日期</StyledTableCell>
                                <StyledTableCell align="right">買/賣價格</StyledTableCell>
                                <StyledTableCell align="right">數量</StyledTableCell>
                                <StyledTableCell align="right">投資資金</StyledTableCell>
                                <StyledTableCell align="right">保留現金</StyledTableCell>

                                <StyledTableCell align="right">總資產</StyledTableCell>
                                <StyledTableCell align="center">資產報酬率</StyledTableCell>


                            </TableRow>
                        </TableHead>
                        {result != null && <TableBody>
                            {result.tran.g_tran.map((row) => (
                                <StyledTableRow
                                    key={Math.random()}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <StyledTableCell component="th" scope="row">
                                        {row.tp === 0 ? <Button variant="contained" color="warning" fullWidth disableElevation>買入</Button> : <Button disableElevation variant="contained" color="info" fullWidth>賣出</Button>}
                                    </StyledTableCell>
                                    <StyledTableCell >{row.ymd}</StyledTableCell>
                                    <StyledTableCell align="right">{row.tp === 0 ? row.bprice : row.buy_price + " / " + row.sprice}元</StyledTableCell>
                                    <StyledTableCell align="right">{row.count}股</StyledTableCell>
                                    <StyledTableCell align="right">{priceWord(row.inv_money)}</StyledTableCell>
                                    <StyledTableCell align="right">{priceWord(row.remain_money)}</StyledTableCell>
                                    <StyledTableCell align="right">{priceWord(row.remain_money + row.inv_money)}</StyledTableCell>
                                    <StyledTableCell align="right">
                                        <Button variant="contained" color={row.asset_roi >= 0 ? "error" : "success"} fullWidth disableElevation>{row.asset_roi}%</Button>
                                    </StyledTableCell>


                                </StyledTableRow >
                            ))}
                        </TableBody>}
                    </Table>
                </TableContainer>


            </DialogContent>
            <DialogActions>
                <Button fullWidth disableElevation variant="contained" color="inherit" onClick={handleClose}>關閉</Button>
            </DialogActions>
        </Dialog>
    );
}

