import React, { useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { priceWord, addIncColor } from '../util/Money'

export default function Meta3(props) {
    const theme = useTheme();

    const openDetail = () => {
        if(props.setOpenSetting2 != null) props.setOpenSetting2(true)
    }

    return (
        <>
            <ButtonGroup variant="text" sx={{ width: '100%', mt: 0, mb: 0, justifyContent: 'center' }} >
                <Button sx={{ width: '50%' }} disableElevation color="inherit" onClick={e => openDetail()}>
                    <Stack sx={{ justifyContent: 'center', width: "100%" }}>
                        <Typography variant="caption" sx={{ alignSelf: 'center', flexGrow: 1 }}  >盈虧</Typography>
                        <Typography sx={{ alignSelf: 'center', fontSize: 30 }}>{props.row.v1}%</Typography>
                        {/* <Button fullWidth size="small" disableElevation color={"secondary"} variant="contained" >PR {props.row.rank}</Button> */}
                    </Stack>
                </Button>
                <Button sx={{ width: '50%' }} color="inherit" onClick={e => openDetail()} >
                    <Stack sx={{ justifyContent: 'center', width: "100%" }} >
                        <Typography variant="caption" sx={{ alignSelf: 'center', flexGrow: 1 }}  >{props.runDay}日成本</Typography>
                        <Typography sx={{ alignSelf: 'center', fontSize: 30 }}>{priceWord(props.row.v2)}</Typography>
                    </Stack>
                </Button>
            </ButtonGroup>
        </>
    );
}
