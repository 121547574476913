import React, { useEffect, useRef } from 'react';
import RecItem from './RecItem1'
import { Height } from '../util/ScreenHeight'
import RecSelector from './RecSelector'
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import { getId } from '../util/ID'

import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { Button } from '@mui/material';
import RateDetail from './RateDetail'
import DetailChart1 from './DetailChart1'
import DetailKChart from './DetailKChart'
import { putInpQueryParam } from '../util/ID'



export default function RecContainer(props) {
    const [page, setPage] = React.useState(1);
    const [total, setTotal] = React.useState(1)
    const [count, setCount] = React.useState(0)
    const [sort, setSort] = React.useState(localStorage.getItem("GRID_SORT") != null ? localStorage.getItem("GRID_SORT") : "0")

    const [result, setResult] = React.useState(null);
    const [menu, setMenu] = React.useState(null)
    const [loading, setLoading] = React.useState(false)
    const [rules, setRules] = React.useState(null)
    const [openSetting, setOpenSetting] = React.useState(false);
    const [stock, setStock] = React.useState(null);

    const [openSetting1, setOpenSetting1] = React.useState(false);
    const [openSetting2, setOpenSetting2] = React.useState(false);
    const [id, setId] = React.useState(null);
    const [sid, setSid] = React.useState(null);

    const [regTp, setRegTp] = React.useState(-1);
    const [runDay, setRunDay] = React.useState(-1);
    const [stp, setSTP] = React.useState(-1);
    const [searchSid, setSearchSid] = React.useState(null);
    const [month, setMonth] = React.useState(localStorage.getItem("GRID_MONTH") != null ? localStorage.getItem("GRID_MONTH") : "1")

    const [row, setRow] = React.useState(null)

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handlePageChange = (event, value) => {
        setPage(value)
    };

    const updateSelector = (sort, selectMonth, sid) => {
        setMonth(selectMonth)
        setSearchSid(sid)
        setSort(sort)
        setPage(1)
    }

    const fetchData = () => {
        setLoading(true)
        // let month = localStorage.getItem("GRID_MONTH") != null ? localStorage.getItem("GRID_MONTH") : "6"
        let count = localStorage.getItem("GRID_COUNT") != null ? localStorage.getItem("GRID_COUNT") : "10"
        let money = localStorage.getItem("GRID_MONEY") != null ? localStorage.getItem("GRID_MONEY") : "100000"
        let money1 = localStorage.getItem("GRID_MONEY1") != null ? localStorage.getItem("GRID_MONEY1") : "50000"
        let upPercent = localStorage.getItem("GRID_UP_PERCENT") != null ? localStorage.getItem("GRID_UP_PERCENT") : "100"
        let downPercent = localStorage.getItem("GRID_DOWN_PERCENT") != null ? localStorage.getItem("GRID_DOWN_PERCENT") : "30"
        let rules = localStorage.getItem('GRID_SEARCH_RULES') != null ? JSON.parse(localStorage.getItem('GRID_SEARCH_RULES')) : []

        let sliptTp = localStorage.getItem("GRID_SLIPT_TP") != null ? localStorage.getItem("GRID_SLIPT_TP") : "0"
        let percent = localStorage.getItem("GRID_PERCENT") != null ? localStorage.getItem("GRID_PERCENT") : "10"
        let bsTp = localStorage.getItem("GRID_BS_TP") != null ? localStorage.getItem("GRID_BS_TP") : "0"
        let bsCount = localStorage.getItem("GRID_BS_COUNT") != null ? localStorage.getItem("GRID_BS_COUNT") : "1000"
        let bsPercent = localStorage.getItem("GRID_BS_PERCENT") != null ? localStorage.getItem("GRID_BS_PERCENT") : "10"


        let param = {
            rules:rules,
            sn: getId(),
            page: page,
            test_month: month,
            money: money,
            money1: money1,
            sort: sort,
            inv_cash_percent: (money1 * 100) / money,
            net: {
                split_tp: sliptTp,
                split_count: count,
                split_percent: percent,
                up_down_tp: 0,
                up_percent: upPercent,
                down_percent: downPercent,
                bs_tp: bsTp,
                bs_count: bsCount,
                bs_percent: bsPercent
            }

        }

        if (localStorage.getItem("grid_lic") != null) {
            param['lic'] = localStorage.getItem("grid_lic")
        }
        if (searchSid != null) {
            param['sids'] = [searchSid]
        }

        param = putInpQueryParam(param)
        
        fetch("/grid/v1/backtest", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    setLoading(false)
                    
                    if (result != null && result.status === 0) {
                        if (result.tran != null && result.tran.length > 0) {
                            setTotal(result.total_page)
                            setResult(result.tran)
                            setCount(result.total_size)
                        } else {
                            setCount(0)
                            setTotal(0)
                            setResult([])
                        }
                    } else {
                        setCount(0)
                        setTotal(0)
                        setResult([])
                    }
                }
            );
    }

    useEffect(() => {
        fetchData()
    }, [page, month, count, searchSid, sort]);


    const updateLove = (bid, sid) => {
        if (bid != null && sid != null && result != null) {
            let nresult = result.map(r => {
                if (r.s === sid && r.b === bid) {
                    r['islv'] = !r.islv
                    return r
                } else return r
            })
            setResult(nresult)
        }
    }

    const openDetail = (stock) => {
        setStock(stock)
        setOpenSetting(true)
    }

    const openDetail1 = (sid) => {
        setSid(sid)
        setOpenSetting1(true)
    }

    const openDetail2 = (id) => {
        setSid(id)
        setOpenSetting2(true)
    }

    return (
        <Box style={{ flexDirection: 'column', height: '100%', overflowY: 'hidden' }} sx={{}}>
            {/* {openSetting && <RateDetail openDetail2={openDetail2} openDetail1={openDetail1} stock={stock} open={openSetting} close={setOpenSetting}></RateDetail>}
            {openSetting1 && <DetailChart1 regTp={regTp} runDay={runDay} id={id} sid={sid} open={openSetting1} close={setOpenSetting1} />}
            {openSetting2 && <DetailKChart sid={sid} open={openSetting2} close={setOpenSetting2} />} */}


            <Box style={{ flexDirection: 'column', height: '100%', overflowY: 'hidden' }} sx={{}}>
                <RecSelector path={props.path} fetchData={fetchData} setMenu={setMenu} refresh={updateSelector} enableSetting={true} />

                {loading ? <Box sx={{ position: 'absolute', top: Height.header + Height.subheader - 2, width: '100%' }}><LinearProgress color="info" /></Box> : <Grid container spacing={0} sx={{ p: 0.5, width: '100%', bgcolor: 'background.paper', position: 'absolute', top: Height.header + Height.subheader, bottom: props.ismobile || fullScreen ? 100 : 55, overflowY: 'scroll' }}>
                    {result != null && result.map(row => {
                        return <Grid key={row.company.stock_no} item xs={12} sm={6} md={4} lg={3}>
                            <RecItem fetchData={fetchData} openDetail2={openDetail2} openDetail1={openDetail1} openDetail={openDetail} updateLove={updateLove} tp={regTp} key={row.company.stock_no} row={row} menu={menu} runDay={runDay} />
                        </Grid>
                    })}
                    {result != null && result.length === 0 &&
                        <Typography variant="body1" sx={{ width: '100%', textAlign: 'center', alignSelf: 'center', m: 1 }}>很抱歉，無法找到符合條件的股票。</Typography>
                    }

                </Grid>}

                {result != null && result.length > 0 && < Box style={{ flexShrink: 0 }} sx={{ position: 'absolute', bottom: props.ismobile || fullScreen ? 60 : 0, width: '100%', mt: 0.5, mb: 0.5, display: 'flex', justifyContent: "center" }}>
                    <Pagination page={page} size="small" siblingCount={1} boundaryCount={1} count={total} variant="outlined" shape="rounded" onChange={(event, value) => {
                        setPage(value)
                    }} />
                    {/* (共{count}筆) */}
                </Box>}
            </Box >


        </Box >
    );
}
