import React, { useEffect, useRef } from 'react';
import { Height } from '../util/ScreenHeight'
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import { Divider, List, ListItem, Typography } from '@mui/material';
import { getId } from '../util/ID'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Stack } from '@mui/system';

export default function CLanding(props) {
    const [page, setPage] = React.useState(1);
    const [total, setTotal] = React.useState(100)

    const [showEmpty, setShowEmpty] = React.useState(false);
    const [emptyMsg, setEmptyMsg] = React.useState('');
    const [result, setResult] = React.useState(null);
    const [menu, setMenu] = React.useState(null)
    const [loading, setLoading] = React.useState(false)

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const fetchData = () => {
    }

    useEffect(() => {
        fetchData()
    }, []);


    return (
        <Box style={{ flexDirection: 'column', position: 'absolute', width: '100%', top: Height.header + 10, bottom: fullScreen ? 55 : 0, overflowY: 'scroll' }} sx={{}}>
            <Box sx={{ pl: 2, pr: 2 }}>
                {/* <Typography variant='h5' fontWeight={"bold"}>何謂動能投資法</Typography>
                <Typography variant='body1'>動能投資法是由Andreas F. Clenow所發明</Typography> */}

                <Typography sx={{ mt: 2, textAlign: "center" }} variant='h4' fontWeight={"bold"}>說明文件</Typography>
                {/* <Typography sx={{ mt: 2 }} variant='body1' ></Typography> */}

                <Typography sx={{ mt: 5 }} variant='h5' fontWeight={"bold"}>網格交易法是什麼?</Typography>
                <Typography variant='body1'>
                    網格交易法是一種利用價格波動進行交易的策略。這種策略將價格區間分成固定的格子（網格），然後在這些價格水平上執行買入和賣出。舉例來說，如果一支股票的價格在50元到60元之間波動，投資者可以設置一個網格，每隔1元設置一個格子。他們可以在50元、51元、52元等每個關鍵價格上交易。當股價上升到51元時，執行賣出交易；當股價下降到50元時，執行買入交易。這樣，無論股價上升還是下跌，只要在這些價格格子內波動，交易者就有機會獲利。這種策略能夠在市場價格震盪時，以相對固定的範圍進行交易，提供了穩定的交易機會。然而，交易者需要謹慎設置網格間距和適當控制風險，以確保在波動市場中獲得持續且可控的利潤。
                    <p></p>
                </Typography>
                <Box
                    component="img"
                    sx={{
                        maxHeight: { xs: '50vh', md: '95vh' },
                        maxWidth: { xs: '50wh' },
                    }}
                    src="explain.png"
                />

                <ul>
                    <li><strong>天花板價：</strong> 網格交易法通常在一個區間內操作，超過此區間則不進行交易，該區間的最高價稱為天花板價格。</li>
                    <li><strong>地板價：</strong> 該區間的最低價稱為地板價格。</li>
                    <li><strong>首次買入：</strong> 使用網格策略進行布局的初始交易。</li>
                    <li><strong>網格線：</strong> 當價格觸碰網格線時，就會進行交易。如果價格在同一條網格線上來回不斷穿梭，則僅會進行一次的交易。</li>
                </ul>



                <Typography sx={{ mt: 5 }} variant='h5' fontWeight={"bold"}>網格線怎麼產生?</Typography>
                <Box
                    component="img"
                    sx={{
                        maxHeight: { xs: '50vh', md: '95vh' },
                        maxWidth: { xs: '50wh' },
                    }}
                    src="explain1.png"
                />
                <Typography variant='body1'>
                    系統提供三種方式
                </Typography>
                <ul>
                    <li><strong>地板價往上固定網格：</strong> 在天花板價格與地板價格區間之間，劃分固定數量的格子。例如：在10-100元之間，固定網格為9條，這樣每10元就會有一條網格線。</li>
                    <li><strong>地板價往上固定%：</strong> 以地板價為第一個基準，按照某個百分比劃定線條。例如：以10元為地板價格，固定百分比為10%，第一條線為11元，第二條為12.1元，依此類推。</li>
                    <li><strong>首次購買價上下固定%：</strong> 以首次購買價為第一個基準，按照某個百分比劃定線條，上方為正百分比，下方為負百分比。例如：以10元為首次購買價格，固定百分比為10%，網格線將在11元、9元、12.1元、8.1元等位置。</li>
                </ul>



                <Typography sx={{ mt: 5 }} variant='h5' fontWeight={"bold"}>到了網格線，該買賣多少股票?</Typography>
                <Box
                    component="img"
                    sx={{
                        maxHeight: { xs: '50vh', md: '95vh' },
                        maxWidth: { xs: '50wh' },
                    }}
                    src="explain2.png"
                />
                <Typography variant='body1'>
                    系統提供三種方式
                </Typography>
                <ul>
                    <li><strong>投資跟保留現金固定比例：</strong> 例如總資金為10萬，其中5萬投資，5萬保留現金。這樣的投資與保留現金比例為1:1。若投資的5萬因股票上漲變成了7萬，比例變為7(投資):5(現金)。系統會要求從投資市場賣出1萬元，使投資變成6萬，現金增加1萬變成6萬，維持比例為1:1。反之，若投資的5萬因股票下跌變成了3萬，比例變為3(投資):5(現金)。系統會要求買入1萬元股票，使投資增加為4萬，現金減少為4萬，維持比例為1:1。</li>
                    <li><strong>固定股數：</strong> 每次買賣都使用相同的固定股數。</li>
                    <li><strong>庫存%：</strong> 以庫存為基準，買賣出多少百分比的庫存。</li>
                </ul>


            </Box>





        </Box>
    );
}
