import React, { useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import { deepOrange, green } from '@mui/material/colors';
import Badge from '@mui/material/Badge';
import { priceWord } from '../util/Money'

export default function Meta1(props) {
    const theme = useTheme();


    const clickDetail = () => {
        if (props.setOpenSetting2 != null) props.setOpenSetting2(true)
    }

    return (
        <>
            <ButtonGroup variant="text" sx={{ width: '100%', mt: 0, mb: 0, justifyContent: 'center' }} >
            <Button sx={{ width: '34%' }} disableElevation color="inherit" onClick={e => clickDetail()}>
                    <Stack sx={{ justifyContent: 'center', width: "100%" }}>
                        <Typography variant="caption" sx={{ alignSelf: 'center', flexGrow: 1 }}  >{"買/賣次數"}</Typography>
                        <Typography sx={{ alignSelf: 'center', fontSize: 30 }}>{props.row.tran.buy_count}/{props.row.tran.sell_tran}</Typography>
                    </Stack>
                </Button>
                <Button sx={{ width: '66%' }} disableElevation color="inherit" onClick={e => clickDetail()}>
                    <Stack sx={{ justifyContent: 'center', width: "100%" }}>
                        <Typography variant="caption" sx={{ alignSelf: 'center', flexGrow: 1 }}  >{"預估損益(元)"}</Typography>
                        <Typography sx={{ alignSelf: 'center', fontSize: 30 }}>{priceWord(props.row.tran.earn)}</Typography>
                    </Stack>
                </Button>
            </ButtonGroup>

            <Divider 
            ></Divider>
            <ButtonGroup variant="text" sx={{ width: '100%', mt: 0, mb: 0, justifyContent: 'center' }} >


                <Button sx={{ width: '34%', borderRadius: 0 }} disableElevation  onClick={e => clickDetail()} color={props.row.tran.roi < 0 ? "success": "error"} variant={props.row.tran.roi < 0 ? "contained": "text"}>
                    <Stack sx={{ justifyContent: 'center', width: "100%" }}>
                        <Typography variant="caption" sx={{ alignSelf: 'center', flexGrow: 1 }}  >{"整體%"}</Typography>
                        <Typography sx={{ alignSelf: 'center', fontSize: 30 }}>{props.row.tran.roi}</Typography>
                    </Stack>
                </Button>
                <Button sx={{ width: '33%' }} disableElevation onClick={e => clickDetail()} color={"inherit"}>
                    <Stack sx={{ justifyContent: 'center', width: "100%" }}>
                        <Typography variant="caption" sx={{ alignSelf: 'center', flexGrow: 1 }}  >{"已實現%"}</Typography>
                        <Typography sx={{ alignSelf: 'center', fontSize: 30 }}>{props.row.tran.real_roi}</Typography>
                    </Stack>
                </Button>
                <Button sx={{ width: '33%' }}  onClick={e => clickDetail()} color={"inherit"}>
                    <Stack sx={{ justifyContent: 'center', width: "100%" }}>
                        <Typography variant="caption" sx={{ alignSelf: 'center', flexGrow: 1 }}  >{"未實現%"}</Typography>
                        <Typography sx={{ alignSelf: 'center', fontSize: 30 }}>{props.row.tran.un_real_roi}</Typography>
                    </Stack>
                </Button>

            </ButtonGroup>

        </>
    );
}
