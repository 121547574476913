import React, { useEffect, useRef } from 'react';
// import { createTheme, ThemeProvider } from '@mui/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Routes from './Routes'
import ColorModeContext from './util/ColorMode'
// import {fpPromise} from './util/BasicInfo'
import { getId, getLic } from './util/ID'
import * as echarts from 'echarts/core';
import {EchartTheme, EchartDarkTheme} from './util/EChartTheme'
import { orange, red } from '@mui/material/colors';
echarts.registerTheme('light', EchartTheme);
echarts.registerTheme('dark', EchartDarkTheme);

function MobileApp() {
    // const [mode, setMode] = React.useState('light');
    const [mode, setMode] = React.useState(localStorage.getItem("colormode") != null ? localStorage.getItem("colormode") : 'light');

    const checkLic = () => {
        let lic = localStorage.getItem('grid_lic')

        if (lic != null) {
            let a = {
                lic: lic,
                sn: getId()
            }

            fetch("/grid/v1/payment/checklic", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(a)
            })
                .then(res => res.json())
                .then(
                    result => {
                        if (result.status === 0) {
                            if (result.hlic != null && (result.hlic.licok === 1 || result.hlic.tp === 3)) {
                                localStorage.setItem('noad', 1);
                                if (window.webkit != undefined) window.webkit.messageHandlers.setAD.postMessage("1");
                            } else {
                                localStorage.setItem('noad', 0);
                                if (window.webkit != undefined) window.webkit.messageHandlers.setAD.postMessage("0");
                            }
                        } else {
                            localStorage.setItem('noad', 0);
                            if (window.webkit != undefined) window.webkit.messageHandlers.setAD.postMessage("0");
                        }
                    },
                    error => {
                        localStorage.setItem('noad', 0);
                        if (window.webkit != undefined) window.webkit.messageHandlers.setAD.postMessage("0");
                    }
                );
        } else localStorage.setItem('noad', 0);
    }

    useEffect(() => {
        if(localStorage.getItem('grid_lic') == null && localStorage.getItem('lic') != null) {
            localStorage.setItem('grid_lic', localStorage.getItem('lic'))
            localStorage.removeItem('lic')
        }

        checkLic();
    }, []);




    const colorMode = React.useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode) => {
                    let curMode = prevMode === 'light' ? 'dark' : 'light'
                    localStorage.setItem("colormode", curMode)
                    return curMode
                });
            },
        }),
        [],
    );

    // const theme = React.useMemo(
    //     () =>
    //         createTheme({
    //             palette: {
    //                 neutral: {
    //                     main: '#ebdbd5',
    //                     contrastText: '#fff',
    //                 },
    //                 primary: {
    //                     main: "#FFC75F"
    //                 },
    //                 secondary: {
    //                     main: "#fbfbfb"
    //                 },
    //                 mode: mode
    //             },
    //         }),
    //     [mode],
    // );

    const theme = React.useMemo(
        () =>
            createTheme({
                palette: {
                    neutral: {
                        main: red[900],
                        contrastText: '#fff',
                    },
                    // primary: {
                    //     main: "#344157"// "#"
                    // },
                    secondary: {
                        main: "#f2f2f2"
                    },

                    mode: mode
                },
            }),
        [mode],
    );

    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <Routes />
            </ThemeProvider>
        </ColorModeContext.Provider>
    );

}

export default MobileApp;
