import React, { useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import { deepOrange, green } from '@mui/material/colors';
import Badge from '@mui/material/Badge';
import { priceWord, addIncColor } from '../util/Money'

export default function Meta2(props) {
    const theme = useTheme();

    return (
        <>
            <ButtonGroup variant="text" sx={{ width: '100%', mt: 0, mb: 0, justifyContent: 'center' }}>
                <Button sx={{ width: '50%' }} disableElevation color="inherit">
                    <Stack sx={{ justifyContent: 'center', width: "100%" }}>
                        <Typography variant="caption" sx={{ alignSelf: 'center', flexGrow: 1 }}  >主力總買賣超</Typography>
                        <Typography sx={{ color:props.row.v1 <0 ? green[500]:null, alignSelf: 'center', fontSize: 30 }}>{priceWord(props.row.v1)}</Typography>
                        {/* <Button fullWidth size="small" disableElevation color={"secondary"} variant="contained" >PR {props.row.rank}</Button> */}
                    </Stack>
                </Button>
                <Button sx={{ width: '50%' }} color="inherit" >
                    <Stack sx={{ justifyContent: 'center', width: "100%" }}>
                        <Typography variant="caption" sx={{ alignSelf: 'center', flexGrow: 1 }}  >{props.runDay}日平均成交金額</Typography>
                        <Typography sx={{ alignSelf: 'center', fontSize: 30 }}>{priceWord(props.row.money)}</Typography>
                    </Stack>
                </Button>
            </ButtonGroup>
        </>
    );
}
