import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart, BarChart, CandlestickChart } from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    MarkAreaComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
    VisualMapComponent,
    VisualMapContinuousComponent,
    VisualMapPiecewiseComponent,
} from 'echarts/components';
import {
    CanvasRenderer,
} from 'echarts/renderers';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import BuySellChart from './BuySellChart';
import { grey } from '@mui/material/colors';

echarts.use(
    [VisualMapComponent, VisualMapContinuousComponent, VisualMapPiecewiseComponent, TitleComponent, TooltipComponent, GridComponent, LineChart, CandlestickChart, CanvasRenderer, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent, MarkAreaComponent, BarChart]
);


const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        // marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        // width: `calc(100% + ${6 + (theme.spacing(1))}px)`
    }
}));



export default function MainBroker(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [option, setOption] = React.useState(null);
    const [result, setResult] = React.useState(null);

    const [open, setOpen] = React.useState(false);

    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
    };

    useEffect(() => {
        if (props.open) setOpen(true)
        else setOpen(false)
    }, [props.open]);

    function genoption1(r) {
        let k = {
            animation: false,
            tooltip: {
                show: true
            },
            // title: [
            //     {
            //         top: '20',
            //         left: 'center',
            //         text: result.item.name,
            //         textStyle: {
            //             fontSize: 22
            //         }
            //     },
            // ],
            grid: [{
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                containLabel: true
            }],
            axisPointer: {
                show: true
            },
            xAxis: [{
                type: 'category',
                boundaryGap: false,
                data: r.map(row => row.x),
            }],
            yAxis: [{
                scale: true,
                type: 'value',
                position: 'left',
                show: true,
            }],
            series: [
                {
                    name: "買賣超",
                    type: 'line',
                    data: r.map(row => row.y),
                    smooth: true,
                    showSymbol: true,
                    symbolSize: 8,
                    endLabel: {
                        show: true
                    },
                    lineStyle: {
                        width: 4
                    }
                }
            ]
        };
        return k;
    }

    const fetchData = () => {
        let param = {
            id: props.id,
            stock: props.stock,
            day: props.runDay
        }

        if (localStorage.getItem("grid_lic") != null) {
            param['lic'] = localStorage.getItem("grid_lic")
        }

        fetch("/chip/v1/app/main", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null) {
                        setResult(result.m)
                    }
                }
            );
    }


    useEffect(() => {
        if (props.id != null && props.stock != null) fetchData()
    }, []);

    useEffect(() => {
        // if (result != null) setOption(genoption1)
    }, [result]);

    const getColor = () => {
        if (result != null && result.price != null) {
            if (result.price.i > 0) return "red"
            else if (result.price.i < 0) return "green"
            else return null
        } else return null
    }

    const getInv = () => {
        if (result != null && result.price != null) {
            if (result.price.i > 0) return "▲" + result.price.i + '元'
            else if (result.price.i < 0) return "▼" + result.price.i + '元'
            else return result.price.i + '元'
        } else if (result.price != null)
            return result.price.i + '元'
        else return '▲0元'
    }

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
        >
            <DialogTitle sx={{ p: 0 }}>
            </DialogTitle>
            <DialogContent sx={{ pl: 1, pr: 1 }}>
                <Typography sx={{mt:2}} variant='h5'>{props.name}{props.stock}
                {props.id === 2?"主力買超":"主力賣超"}</Typography>
                {result != null && result.map((row, index) => {
                    return <>
                        <Stack spacing={0.3} direction={"row"} sx={{ pl: 0.5, mt:1 }}>
                            <Box sx={{pl:1.2, pr: 1.2, pt:0.7, pb:0.7, bgcolor:grey[200]}}>{index+1}</Box>
                            <Typography sx={{ display: "inline", alignSelf: "center", fontSize: 20 }} >{row.name}</Typography>
                            <Typography sx={{ display: "inline", ml: 0.5, fontSize: 12, alignSelf: "center", flexGrow: 1 }} >{row.id}</Typography>
                        </Stack>
                        <BuySellChart data={row.data} top={10}/>
                        <Divider sx={{ mt: 1 }} variant="fullWidth" ></Divider>
                    </>
                })}

               
            </DialogContent>
            <DialogActions>
                <Button fullWidth disableElevation variant="contained" color="inherit" onClick={handleClose}>關閉</Button>
            </DialogActions>
        </Dialog>
    );
}

