import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart, BarChart, CandlestickChart } from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    MarkAreaComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
    VisualMapComponent,
    VisualMapContinuousComponent,
    VisualMapPiecewiseComponent,
} from 'echarts/components';
import {
    CanvasRenderer,
} from 'echarts/renderers';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import BuySellChart1 from './BuySellChart1';
import { green, grey } from '@mui/material/colors';
import ButtonGroup from '@mui/material/ButtonGroup';
import { priceWord, addIncColor } from '../util/Money'

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Grid from '@mui/material/Grid';

echarts.use(
    [VisualMapComponent, VisualMapContinuousComponent, VisualMapPiecewiseComponent, TitleComponent, TooltipComponent, GridComponent, LineChart, CandlestickChart, CanvasRenderer, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent, MarkAreaComponent, BarChart]
);


const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        // marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        // width: `calc(100% + ${6 + (theme.spacing(1))}px)`
    }
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: grey[700],
        color: theme.palette.common.white,
        whiteSpace: "nowrap",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 17,
        whiteSpace: "nowrap",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        // backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function MainBroker1(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [option, setOption] = React.useState(null);
    const [result, setResult] = React.useState(null);
    const [buySell, setBuySell] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [result1, setResult1] = React.useState(null);

    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
    };

    useEffect(() => {
        if (props.open) setOpen(true)
        else setOpen(false)
    }, [props.open]);

    const fetchData = () => {
        let param = {
            b: props.broker,
            s: props.stock,
        }

        if (localStorage.getItem("grid_lic") != null) {
            param['lic'] = localStorage.getItem("grid_lic")
        }

        fetch("/chip/v1/app/main1", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null) {
                        setResult(result.p)
                        setResult1(result.d)
                    }
                }
            );
    }

    useEffect(() => {
        if (result != null) {
            setOption(genoption2(result, "日K"))
        }
    }, [result]);

    useEffect(() => {
        if (props.broker != null && props.stock != null) fetchData()
    }, []);



    function genoption2(vv, title) {
        let k = {
            animation: false,
            tooltip: {
                show: true
            },
            // legend: {
            //     show: true
            // },
            title: [
                {
                    top: '30',
                    left: 0,
                    left: 'left',
                    text: props.name + "(" + props.stock + ")",
                    textStyle: {
                        fontWeight: "normal",
                        fontSize: 22
                    }
                },
                {
                    top: '370',
                    left: 0,
                    left: 'left',
                    text: props.broker_name + "(" + props.broker + ")",
                    textStyle: {
                        fontWeight: "normal",
                        fontSize: 22
                    }
                },
            ],
            grid: [{
                top: 70,
                left: 0,
                right: 10,
                height: 250,
                containLabel: true
            }, {
                top: 410,
                left: 0,
                right: 10,
                height: 150,
                containLabel: true
            }],
            axisPointer: {
                show: true,
                link: { xAxisIndex: 'all' },
            },
            dataZoom: [
                {
                    type: 'inside',
                    start: fullScreen ? 75 : 0,
                    end: 100,
                    xAxisIndex: 'all',
                }
            ],
            xAxis: [{
                type: 'category',
                boundaryGap: true,
                data: vv.map(row => row.x),

            }, {
                type: 'category',
                boundaryGap: true,
                data: vv.map(row => row.x),
                gridIndex: 1,
                axisPointer: fullScreen ? {
                    type: 'line',
                    label: { show: false },
                    triggerTooltip: true,
                    handle: {
                        show: true,
                        size: 22
                    }
                } : {}
            }],
            visualMap: [
                {
                    show: false,
                    type: 'piecewise',
                    seriesIndex: 1,
                    min: -90000,
                    max: 90000,
                    pieces: [
                        { min: -90000, max: 0, color: '#78b7c2' },
                        { min: 0, max: 90000, color: "#ffaf38" },
                    ]
                },
            ],

            yAxis: [{
                scale: true,
                type: 'value',
                position: 'left',
                show: true,
                splitLine: {
                    show: true,
                    lineStyle: {
                        opacity: 0.2
                    }
                },
                axisLabel: {
                    inside: true,
                    margin: 0
                },
            }, {
                scale: true,
                type: 'value',
                position: 'left',
                show: true,
                splitLine: {
                    show: true,
                    lineStyle: {
                        opacity: 0.2
                    }
                },
                axisLabel: {
                    inside: true,
                    margin: 0
                },
                gridIndex: 1,
            }],
            series: [
                {
                    name: '股價',
                    type: 'candlestick',
                    data: vv.map(v => { return v.y }),
                    selectedMode: 'single',
                },
                {
                    name: '分點',
                    type: 'bar',
                    data: vv.map(v => { return v.b }),
                    xAxisIndex: 1,
                    yAxisIndex: 1,
                }
            ]
        };



        return k;
    }

    const getDay = (value) => {
        if (value === 0) return "1"
        else if (value === 1) return "5"
        else if (value === 2) return "20"
        else if (value === 3) return "60"
        else if (value === 4) return "120"
        else return ""
    }

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
        >
            <DialogTitle sx={{ p: 0 }}>
            </DialogTitle>
            <DialogContent sx={{ pl: 1, pr: 1 }}>
                {/* <Typography sx={{ mt: 2 }} variant='h5'>{props.name}{props.stock}
                </Typography> */}

                {option != null ?
                    <ReactEChartsCore
                        theme={theme.palette.mode}
                        echarts={echarts}
                        option={option}
                        notMerge={true}
                        lazyUpdate={false}
                        style={{ height: 620, marginTop: 0 }}
                    /> : null}

                {/* <Grid container spacing={2} sx={{  bgcolor: 'background.paper' }}>
                    {result1 != null && result1.map((row, index) => {
                        return <Grid key={index} item xs={12} sm={12} md={12} lg={12}>
                            <Typography variant="h6" sx={{ alignSelf: 'center', flexGrow: 1 }}  >近{getDay(index)}日統計</Typography>
                            <TableContainer component={Paper} sx={{mb:2}}  >
                                <Table size="medium" sx={{ fontSize: 20 }}>
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center">買賣超</StyledTableCell>
                                            <StyledTableCell align="center">金額</StyledTableCell>
                                            <StyledTableCell align="center">買賣超成交比</StyledTableCell>
                                            <StyledTableCell align="center">買進張數</StyledTableCell>
                                            <StyledTableCell align="center">買進均價</StyledTableCell>
                                            <StyledTableCell align="center">賣出張數</StyledTableCell>
                                            <StyledTableCell align="center">賣出均價</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <StyledTableRow
                                            key={index}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <StyledTableCell align="center" sx={{color:row.v <0 ? green[700]:null}}>{(row.v / 1000).toFixed(1)}張</StyledTableCell>
                                            <StyledTableCell align="center" sx={{color:row.m <0 ? green[700]:null}} >{priceWord(row.m)}</StyledTableCell>
                                            <StyledTableCell align="center" sx={{color:row.a <0 ? green[700]:null}}>{row.a}%</StyledTableCell>
                                            <StyledTableCell align="center">{(row.bv / 1000).toFixed(1)}張</StyledTableCell>
                                            <StyledTableCell align="center">{row.bc}元</StyledTableCell>
                                            <StyledTableCell align="center">{(row.sv / 1000).toFixed(1)}張</StyledTableCell>
                                            <StyledTableCell align="center">{row.sc}元</StyledTableCell>
                                        </StyledTableRow >
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </Grid>


                    })}
                </Grid> */}
                <Grid container spacing={2} sx={{ bgcolor: 'background.paper' }}>

                    <Grid key={"1"} item xs={12} sm={12} md={12} lg={12}>
                        <Typography variant="h5" sx={{ alignSelf: 'center', flexGrow: 1 }}  >買賣統計</Typography>
                        <TableContainer component={Paper} sx={{ mb: 2 }}  >
                            <Table size="medium" sx={{ fontSize: 20 }}>
                                <TableHead>
                                    <TableRow>
                                    <StyledTableCell align="center">週期</StyledTableCell>
                                        <StyledTableCell align="center">買賣超</StyledTableCell>
                                        <StyledTableCell align="center">金額</StyledTableCell>
                                        <StyledTableCell align="center">買賣超成交比</StyledTableCell>
                                        <StyledTableCell align="center">買進張數</StyledTableCell>
                                        <StyledTableCell align="center">買進均價</StyledTableCell>
                                        <StyledTableCell align="center">賣出張數</StyledTableCell>
                                        <StyledTableCell align="center">賣出均價</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {result1 != null && result1.map((row, index) => {
                                        return <StyledTableRow
                                            key={index}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <StyledTableCell align="center" sx={{ }}>{getDay(index)}日</StyledTableCell>
                                            <StyledTableCell align="center" sx={{ color: row.v < 0 ? green[700] : null }}>{(row.v / 1000).toFixed(1)}張</StyledTableCell>
                                            <StyledTableCell align="center" sx={{ color: row.m < 0 ? green[700] : null }} >{priceWord(row.m)}</StyledTableCell>
                                            <StyledTableCell align="center" sx={{ color: row.a < 0 ? green[700] : null }}>{row.a}%</StyledTableCell>
                                            <StyledTableCell align="center">{(row.bv / 1000).toFixed(1)}張</StyledTableCell>
                                            <StyledTableCell align="center">{row.bc}元</StyledTableCell>
                                            <StyledTableCell align="center">{(row.sv / 1000).toFixed(1)}張</StyledTableCell>
                                            <StyledTableCell align="center">{row.sc}元</StyledTableCell>
                                        </StyledTableRow >
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>

                    </Grid>



                </Grid>


            </DialogContent>
            <DialogActions>
                <Button fullWidth disableElevation variant="contained" color="inherit" onClick={handleClose}>關閉</Button>
            </DialogActions>
        </Dialog>
    );
}

