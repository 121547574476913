import React, { useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useTheme } from '@mui/material/styles';
import { Height } from '../util/ScreenHeight'
// import ErrorM from '../util/ErrorM'
// import AskDialog from '../util/AskDialog';
// import Photo from '@mui/icons-material/Photo'
// import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import MoreVert from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import RecSetting from './RecSetting';

import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import OutlinedInput from '@mui/material/OutlinedInput';
import AlertD from '../util/AlertD';
import { Stack, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import SearchCompany from './SearchCompany'
import SearchBroker from './SearchBroker';
import Grid from '@mui/material/Unstable_Grid2';
import { putInpQueryParam } from '../util/ID'
import { getId } from '../util/ID'
import LoveSetting from '../rec/LoveSetting1'

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -10,
        top: 5,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 10 + ITEM_PADDING_TOP,
            // width: 250,
        },
    },
};


const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiOutlinedInput-root': {
            borderRadius: 0
        },
    }

    //  '& .MuiDataGrid-colCell, .MuiDataGrid-cell': {
    //     borderRight: `1px solid ${theme.palette.type === 'light' ? '#f0f0f0' : '#303030'
    //         }`,
    // },
}));


export default function RecSelector(props) {
    const [showAlert, setShowAlert] = React.useState(false);
    const [alertMsg, setAlertMsg] = React.useState(null);
    const [openSetting, setOpenSetting] = React.useState(false);

    const theme = useTheme();
    const classes = useStyles();

    const [sid, setSID] = React.useState(null);


    const clickLove = () => {
        if(sid != null)
        setOpenSetting(true)   
    }

    return (
        <Box sx={{ width: '100%', textAlign: 'left', display: 'inline-flex', pt: 0, bgcolor: theme.palette.mode === "light" ? "secondary.light" : null }} >
            {showAlert ? <AlertD desc={alertMsg} open={showAlert} close={setShowAlert}></AlertD> : null}
            {openSetting ? <LoveSetting refresh={props.fetchData}  sid={sid} open={openSetting} close={setOpenSetting}></LoveSetting> : null}

            <Grid container spacing={0.2} sx={{ width: "100%" }} columns={16}>
                <Grid xs={14}>
                    <SearchCompany setSID={setSID} />
                </Grid>
                
                <Grid xs={2}>
                    <Button fullWidth variant='contained' sx={{ height: Height.subheader - 3, maxHeight: Height.subheader, whiteSpace: 'nowrap', borderRadius: '0px' }} size="small" color="error" disableElevation onClick={e => clickLove()}>新增</Button>
                </Grid>
            </Grid>


            {/* <Stack direction={"row"} spacing={0.2} sx={{ width: "100%", height: Height.subheader, maxHeight: Height.subheader }}>
                <Box sx={{ minWidth: "25%" }}>
                    <SearchCompany setSID={setSID} />
                </Box>
                <Box sx={{ minWidth: "25%" }}>
                    <SearchBroker setSID={setBID} />
                </Box>

                <FormControl variant="filled" hiddenLabel >
                    <Select
                        labelId="tpLabel"
                        id="tp"
                        value={regTp}
                        label="tpLabel"
                        onChange={tpChange}
                    >
                        <MenuItem value={0}>成交量大到小</MenuItem>
                        <MenuItem value={2}>成交金額大到小</MenuItem>
                        <Divider />
                        <MenuItem value={1}>成交量小到大</MenuItem>
                        <MenuItem value={3}>成交金額小到大</MenuItem>
                    </Select>
                </FormControl>
                <FormControl variant="filled" sx={{ whiteSpace: "nowrap" }} hiddenLabel >
                    <Select
                        labelId="yearLabel"
                        id="year"
                        value={runYear}
                        label="yearLabel"
                        onChange={handleYearChange}
                    >
                        <MenuItem value={1}>1日</MenuItem>
                        <MenuItem value={5}>5日</MenuItem>
                        <MenuItem value={20}>20日</MenuItem>
                        <MenuItem value={60}>60日</MenuItem>
                        <MenuItem value={120}>120日</MenuItem>
                    </Select>
                </FormControl>


            </Stack> */}
            {/* {props.enableSetting && <Button variant='contained' sx={{ height: Height.subheader - 3, maxHeight: Height.subheader, whiteSpace: 'nowrap', borderRadius: '0px', mr: 0.2}} size="small" color="secondary" disableElevation onClick={e => clear()}>清除</Button>} */}
            {/* {props.enableSetting && <Button variant='contained' sx={{ height: Height.subheader - 3, maxHeight: Height.subheader, whiteSpace: 'nowrap', borderRadius: '0px' }} size="small" color="error" disableElevation onClick={e => setOpenSetting(true)}>更多</Button>} */}
        </Box>
    );
}
